var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-row",
            { attrs: { type: "flex" } },
            [
              _c(
                "a-col",
                { staticStyle: { "margin-right": "10px" } },
                [
                  _vm.get(_vm.user, "profile_picture")
                    ? _c("img", {
                        staticClass: "user_pic",
                        attrs: {
                          src: _vm.get(_vm.user, "profile_picture_url"),
                          alt: ""
                        }
                      })
                    : _c("a-avatar", { attrs: { size: 64, icon: "user" } })
                ],
                1
              ),
              _c("a-col", { attrs: { span: 14 } }, [
                _c("h2", [_vm._v(_vm._s(_vm.get(_vm.user, "matricule")))]),
                _c("strong", [
                  _vm.isFemme(_vm.get(_vm.user, "sexe")) &&
                  _vm.hasConj(_vm.get(_vm.user, "nom_conj"))
                    ? _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.get(_vm.user, "nom_conj")) +
                            " "
                        ),
                        _c("i", [_vm._v("née")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.get(_vm.user, "full_name")) +
                            "\n          "
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.user.full_name) +
                            "\n          "
                        )
                      ])
                ]),
                _vm.get(_vm.user, "dtn")
                  ? _c("span", [
                      _vm._v("\n          né(e) le\n          "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm._f("date")(_vm.user.dtn)))
                      ])
                    ])
                  : _vm._e(),
                _c("br"),
                _vm.get(_vm.user, "dt_autorisation")
                  ? _c(
                      "span",
                      [
                        _vm._v("\n          Date d'installation\n          "),
                        _c(
                          "strong",
                          { staticStyle: { "margin-right": "1%" } },
                          [
                            _vm._v(
                              _vm._s(_vm._f("date")(_vm.user.dt_autorisation))
                            )
                          ]
                        ),
                        _vm.isDateInstallationEditable
                          ? _c("a-icon", {
                              attrs: { type: "edit" },
                              on: { click: _vm.editDateInstallation }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c("br"),
                _c("br")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }