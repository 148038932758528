<template>
  <div>
    <EtatActiviteEditor
      ref="CreateForm"
      :visible="visible"
      @cancel="handleCancel"
      @close="closeModal"
      @create="handleCreate"
      :key="newComponentKey"
      :processing="saving"
      :etatActivite="selectedEtatActivite"
      :dateInstallation="userDateInstallationText"
    />
    <DateInstallationEditor
      ref="DateInstallationForm"
      :visible="dateInstallationModalVisible"
      :key="newDateInstallationComponentKey"
      :dateInstallation="myUserDateInstallationText"
      :processing="dateInstallationProcessing"
      @close="hideDateInstallationModal"
      @cancel="hideDateInstallationModal"
      @submit="updateDateInstallation"
    />
    <a-row type="flex" justify="center" class="table_head">
      <a-col :span="20">
        <user-info :isDateInstallationEditable="true" :user="user" @edit="editDateInstallation"/>
      </a-col>
    </a-row>
    <a-row type="flex" justify="center" class="table_head">
      <a-col :span="20">
        <h1>Etat activites</h1>
      </a-col>
      <a-col :span="20" class="mrt mrb">
        <a-button
          class="editable-add-btn"
          type="primary"
          @click="showModal"
          v-if="!processing"
          >Ajouter activité</a-button
        >
      </a-col>

      <a-col :span="20">
        <EtatActivitesList
          :etat-activites="etatActivites"
          :processing="processing"
          @refresh_data="fetchData"
          @delete_file="destroyFile($event, id)"
          @delete="destroyEtatActivite($event, id)"
          @edit="editEtatActivite($event)"
        />
      </a-col>
    </a-row>
  </div>
</template>
<script>
  import EtatActivitesList from "@/components/common/EtatActivite";
  import EtatActiviteEditor from "@/components/common/EtatActiviteEditor";
  import UserInfo from "@/components/common/UserInfo";
  import DateInstallationEditor from "./DateInstallationEditor";
  import moment from "moment";
  import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

  const MAX_AGE = 90;
  const MIN_AGE = 19;

  export default {
    name: "Index",
    components: {
      EtatActivitesList,
      EtatActiviteEditor,
      UserInfo,
      DateInstallationEditor,
    },
  data() {
    return {
      userId: null,
      selectedEtatActivite: null,
      processing: true,
      visible: false,
      saving: false,
      dateInstallationProcessing: false,
      dateInstallationModalVisible: false,
      newComponentKey: 0,
      newDateInstallationComponentKey: 1,
      myUserDateInstallationText: null,
    };
  },

  created() {
    this.clearSelectedAdherent();
    this.userId = this.$route.params["id"];
    this.fetchData();
  },
  computed: {
    ...mapGetters({
      etatActivites: "getSelectedAdherentEtatActivites",
    }),
    ...mapState({
      user: (state) => state.adherents.selected_adherent,
    }),
    userDateInstallationText() {
      return this.user ? this.user.dt_autorisation : "";
    }
  },
  methods: {
    moment,
    editDateInstallation() {
      this.getDateInstallationModalComponentKey();
      this.setUserDateInstallation();
      this.showDateInstallationModal();
    },
    setUserDateInstallation() {
      this.myUserDateInstallationText = this.userDateInstallationText;
    },
    hideDateInstallationModal() {
      this.dateInstallationModalVisible = false;
    },
    showDateInstallationModal() {
      this.dateInstallationModalVisible = true;
    },
    updateDateInstallation() {
      const form = this.$refs.DateInstallationForm.form;
      let that = this;
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        let user = {
          id: this.user.id,
          dt_autorisation: this.formatDate(
                  form.getFieldsValue()["dateInstallation"],
                  "YYYY-MM-DD"
          ),
        };
        this.startProcessing();
        this.startSaving();
        this.startDateInstallationProcessing();
        this.updateUser({...user})
                .then(() => that.fetchData())
                .catch((error) => {
                  that.stopProcessing();
                  that.stopSaving();
                  that.stopDateInstallationProcessing();
                });
      });
    },
    getDateInstallationModalComponentKey() {
      this.newDateInstallationComponentKey = this.getRandomInt();
    },
    getDemandeAttestationComponentKey() {
      this.newComponentKey = this.getRandomInt();
    },
    handleCreate() {
      this.startSaving();
      const form = this.$refs.CreateForm.form;
      let that = this;
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        let fields = form.getFieldsValue();
        let etatActivite = {
          ...fields,
          user_id: this.userId,
          date_changement: this.formatDate(
            fields["date_changement"],
            "YYYY-MM-DD"
          ),
          fin_stage:  this.get(fields, "fin_stage", null) ?
           this.formatDate(
            fields["fin_stage"],
            "YYYY-MM-DD"
          ) : null,
        };
        if (that.selectedEtatActivite) {
          this.update({
            ...etatActivite,
            id: that.selectedEtatActivite.id,
          })
                  .then(() => {
                    this.stopSaving();
                    form.resetFields();
                    this.closeModal();
                    this.fetchData();
                  });
          return;
        }
        this.add(etatActivite)
                .then(() => {
                  this.stopSaving();
                  form.resetFields();
                  this.closeModal();
                  this.fetchData();
                });
      });
    },
    handleCancel() {
      this.stopSaving();
      this.setModalInvisible();
      this.resetSelectedEtatActivite();
    },
    showModal() {
      this.getDemandeAttestationComponentKey();
      this.setModalVisible();
    },
    closeModal() {
      this.stopSaving();
      this.setModalInvisible();
      this.resetSelectedEtatActivite();
    },
    disableRecentDates(current) {
      return (
        current && (current > this.getMinAge() || current < this.getMaxAge())
      );
    },
    getMinAge() {
      return moment().subtract(MIN_AGE, "years");
    },
    getMaxAge() {
      return moment().subtract(MAX_AGE, "years");
    },
    destroyFile(fileId) {
      this.deleteFile(fileId).then(() => this.fetchData());
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    startSaving() {
      this.saving = true;
    },
    stopSaving() {
      this.saving = false;
    },
    setModalVisible() {
      this.visible = true;
    },
    setModalInvisible() {
      this.visible = false;
    },
    startDateInstallationProcessing() {
      this.dateInstallationProcessing = true;
    },
    stopDateInstallationProcessing() {
      this.dateInstallationProcessing = false;
    },
    resetSelectedEtatActivite() {
      this.selectedEtatActivite = null;
    },
    destroyEtatActivite(etatActiviteId) {
      this.startProcessing();
      this.startSaving();
      this.delete(etatActiviteId).then(() => this.fetchData());
    },
    setSelectedEtatActivite(etatActivite) {
      this.selectedEtatActivite = etatActivite;
    },
    editEtatActivite(data) {
      this.setSelectedEtatActivite(data.etatActivite);
      this.showModal();
    },
    async fetchData() {
      this.startProcessing();
      this.startSaving();
      return await this.getAdherentById(this.userId)
              .then(() => this.hideDateInstallationModal())
              .finally(() => {
                this.stopProcessing();
                this.stopSaving();
                this.stopDateInstallationProcessing();
              });
    },
    ...mapActions({
      getAdherentById: "fetchAdminAdherentsById",
      fetchEtatActivites: "fetchAdminUserEtatActivites",
      add: "storeAdminUserEtatActvite",
      update: "updateAdminUserEtatActvite",
      updateUser: "updateAdminAdherent",
      deleteFile: "destroyAdminUserEtatActviteFile",
      delete: "destroyAdminUserEtatActvite",
    }),
    ...mapMutations(["clearSelectedAdherent"]),
  },
};
</script>
<style scoped>
.table_head {
  margin-bottom: 5%;
}
.icons-list >>> .anticon {
  margin-right: 6px;
  font-size: 24px;
}
.mrb {
  margin-bottom: 2%;
}
.mrt {
  margin-bottom: 2%;
}
</style>
