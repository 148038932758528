<template>
  <div>
    <a-spin :spinning="loading">
      <a-row type="flex">
        <a-col style="margin-right : 10px">
          <img
                  class="user_pic"
                  :src="get(user, 'profile_picture_url')"
                  v-if="get(user,'profile_picture')"
                  alt
          />
          <a-avatar v-else :size="64" icon="user" />
        </a-col>
        <a-col :span="14">
          <h2>{{ get(user,'matricule') }}</h2>
           <strong>
            <span v-if="isFemme( get(user,'sexe')) && hasConj( get(user,'nom_conj') )">
              {{ get(user,'nom_conj') }} <i>née</i> {{ get(user,'full_name') }}
            </span>
            <span v-else>
              {{ user.full_name }}
            </span>
          </strong>
          <span v-if="get(user,'dtn')">
            né(e) le
            <strong>{{ user.dtn | date }}</strong>
          </span>
          <br/>
          <span v-if="get(user,'dt_autorisation')">
            Date d'installation
            <strong style="margin-right : 1%">{{
              user.dt_autorisation | date
            }}</strong>
            <a-icon v-if="isDateInstallationEditable" type="edit" @click="editDateInstallation"/>
          </span>
          <br />
          <br />
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>
<script>
  import moment from "moment";

  export default {
    name: "UserInfo",
    data() {
      return {};
    },
    computed: {
      loading() {
        return !this.user;
      },
    },
    props: {
      user: {type: Object},
      isDateInstallationEditable: {type: Boolean, default: () => false},
    },
    methods: {
      moment,
      editDateInstallation() {
        this.$emit("edit");
      },
    },
};
</script>
<style scoped>
.user_pic {
  border-radius: 50%;
  width: 84px;
  height: 84px;
}
</style>