<template>
  <a-modal
    title="Date d'installation"
    :visible="visible"
    @cancel="handleCancel"
    okText="Valider"
    @ok="handleOk"
    :okButtonProps="{ props: { htmlType: 'submit' } }"
  >
    <a-row type="flex" justify="center">
      <a-form
        :layout="formLayout"
        id="change_date_installation"
        name="change_date_installation"
        class="adherent_form"
        :form="form"
      >
        <a-row type="flex" justify="center">
          <a-col :span="24">
            <a-spin :spinning="processing">
              <a-form-item v-bind="formItemLayout" label="Date installation">
                <a-date-picker
                  style="width: 100%;"
                  :format="dateFormat"
                  v-decorator="[
                    'dateInstallation',
                    {
                      rules: [
                        {
                          type: 'object',
                          required: false,
                          message: 'Date de naissance est obligatoire!',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-spin>
          </a-col>
        </a-row>
      </a-form>
    </a-row>
    <template slot="footer">
      <a-button
        key="submit"
        type="primary"
        :loading="processing"
        @click="handleOk"
        >Valider</a-button
      >
    </template>
  </a-modal>
</template>
<script>
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};
export default {
  name: "DateInstallationEditor",
  mounted() {
    if (this.dateInstallation) {
      this.fillTheForm();
    }
  },
  data() {
    return {
      formItemLayout,
      form: this.$form.createForm(this),
    };
  },
  props: ["visible", "dateInstallation", "processing"],
  methods: {
    fillTheForm() {
      this.$nextTick(() =>
              this.form.setFieldsValue({
                dateInstallation: this.momentDate(this.dateInstallation),
              })
      );
    },
    handleClose() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("close");
    },
    handleOk() {
      this.$emit("submit");
    },
  },
};
</script>